// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-prive-js": () => import("./../src/pages/prive.js" /* webpackChunkName: "component---src-pages-prive-js" */),
  "component---src-templates-private-js": () => import("./../src/templates/private.js" /* webpackChunkName: "component---src-templates-private-js" */),
  "component---src-templates-projet-js": () => import("./../src/templates/projet.js" /* webpackChunkName: "component---src-templates-projet-js" */)
}

